import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@app/core/_guards/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { getCsrfToken, getCurrentOrganization, getCurrentUser, getPeoplePositionId, getTokenStorage, SessionStorage, setCsrfToken } from '@app/_shared/services/storage/storage.service';
import { NavigationEnd, Router } from '@angular/router';

declare const environment;
declare const userGuiding;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
      private authService: AuthService,
      private cookieService: CookieService,
      private router: Router,
    ) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isProductionSendUserIdentity();
        }
      });
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = getTokenStorage();
        request = this.setContaUnicoHeaders(request);
        if (
            !request.url.includes('/admission/invite') &&
            !request.url.includes('/authorize') &&
            !request.url.includes('/logout')
        ) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            });
        }

        if (request.method !== 'GET') {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Acesso-Csrf-Token': getCsrfToken(),
                },
                withCredentials: true
            })
        }

        return next.handle(request).pipe(
            map((res) => {
                if (res instanceof HttpResponse && res?.headers?.keys().includes('acesso-csrf-token')) {
                    const csrf_token = res?.headers.get('acesso-csrf-token');
                    setCsrfToken(csrf_token);
                }
                return res
            }),
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.authService.logout();
                    }
                }
                return throwError(err);
            })
        );
    }

  isProductionSendUserIdentity(): void {
    const userInfos = getCurrentUser();
    if (environment.production) {
      userGuiding.identify(userInfos?.id, {
        email: userInfos?.email,
        OrganizationName: userInfos?.organizationName,
        OrganizationId: userInfos?.organization,
        Product: 'unico | people',
        User: 'Candidate'
      });
    }
  }

  setContaUnicoHeaders(request: HttpRequest<any>) {
    const originOrganizationId = getCurrentOrganization();
    const originPositionId = getPeoplePositionId();
    const originAppName = SessionStorage.getItem('originAppName');
    const originContaUnico = SessionStorage.getItem('originContaUnico');

    let headers = {}
    if (originOrganizationId) {
      headers["Acesso-Origin-Organization-Id"] = originOrganizationId;
      this.cookieService.set('tenant', originOrganizationId);
    }
    if (originPositionId) {
      headers["Acesso-Origin-Position-Id"] = originPositionId;
    }
    if (originAppName) {
      headers["Acesso-Origin-App-Name"] = originAppName;
    }
    if (originContaUnico) {
      headers["Acesso-Origin-Conta-Unico"] = originContaUnico;
    }

    request = request.clone({
      setHeaders: headers
    });

    return request;
  }
}
